import React, { useEffect, useRef, useState } from 'react';
import { useTrace } from 'traceit-react-hook';
import { Box, Text, Image, Flex, Button, useBreakpointValue } from '@chakra-ui/react';
import { getRandomComplementaryColor } from './Helpers';
import catMoods from '../images/tailcats.jpg';
import './WhiskersStory.css';

const WhiskersStory = () => {
  const [traceStates, setTraceStates] = useState({
    'friendly and content': false,
    'alert and interested': false,
    'derisive': false,
    'friendly but unsure': false,
    'amicable': false,
    'defensive and angry': false,
    'submissive': false,
    'very happy': false,
    'excited and irritable': false,
    'non-threatening, unsure': false,
    'angry': false,
    'potentially aggressive': false,
  });

  const tailRefs = {
    'friendly and content': useRef(null),
    'alert and interested': useRef(null),
    'derisive': useRef(null),
    'friendly but unsure': useRef(null),
    'amicable': useRef(null),
    'defensive and angry': useRef(null),
    'submissive': useRef(null),
    'very happy': useRef(null),
    'excited and irritable': useRef(null),
    'non-threatening, unsure': useRef(null),
    'angry': useRef(null),
    'potentially aggressive': useRef(null),
  };

  const options = {
    strokeColor: '#00aaee',
    strokeWidth: 3,
    gapPoint: 'right',
    redrawSpeed: 1500,
    canvasPadding: 10,
    onClick: (e) => console.log('Clicked on the trace!', e),
  };

  const catTailTraceColor = '#8e63b5';
  const catTailOptions = {
    ...options,
    strokeWidth: 3,
    strokeColor: getRandomComplementaryColor(catTailTraceColor),
    redrawSpeed: 1500,
    gapPoint: "bottom_left",
    canvasPadding: 12,
  };
  useTrace(tailRefs['friendly and content'], traceStates['friendly and content'], catTailOptions);
  useTrace(tailRefs['alert and interested'], traceStates['alert and interested'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['derisive'], traceStates['derisive'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['friendly but unsure'], traceStates['friendly but unsure'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['amicable'], traceStates['amicable'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['defensive and angry'], traceStates['defensive and angry'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['submissive'], traceStates['submissive'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['very happy'], traceStates['very happy'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['excited and irritable'], traceStates['excited and irritable'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['non-threatening, unsure'], traceStates['non-threatening, unsure'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['angry'], traceStates['angry'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});
  useTrace(tailRefs['potentially aggressive'], traceStates['potentially aggressive'], {...catTailOptions, strokeColor: getRandomComplementaryColor(catTailTraceColor)});

  const handleClick = (mood) => {
    setTraceStates(prev => ({
      ...prev,
      [mood]: true,
    }));
  };

  const handleReset = () => {
    setTraceStates({
      'friendly and content': false,
      'alert and interested': false,
      'derisive': false,
      'friendly but unsure': false,
      'amicable': false,
      'defensive and angry': false,
      'submissive': false,
      'very happy': false,
      'excited and irritable': false,
      'non-threatening, unsure': false,
      'angry': false,
      'potentially aggressive': false,
    });
  }

  const layout = useBreakpointValue({ base: "column", lg: "row" });

  return (
    <Flex direction={layout} justify="space-between" align="start" wrap="wrap">
      <Box flex="1" minWidth={{ base: "100%", lg: "30%" }} mb={{ base: 4, lg: 0 }} pr={{ lg: 4 }} px={{ lg: 4 }}>
        <Text mb={4}>
          Whiskers began his day <Button onClick={() => handleClick('friendly and content')}>friendly and content</Button> on the windowsill, soaking in the sun.
          But a noise outside made him <Button onClick={() => handleClick('defensive and angry')}>defensive and angry,</Button> unsure whether to investigate.
          The mailman’s arrival was met with a <Button onClick={() => handleClick('derisive')}>derisive</Button> flick of his tail, feeling superior.
          <br /><br />
          When his owner returned, Whiskers was <Button onClick={() => handleClick('friendly but unsure')}>friendly but unsure</Button> about attention,
          then settled into an <Button onClick={() => handleClick('amicable')}>amicable</Button> mood after a good ear scratch.
          Spotting another cat, Whiskers felt <Button onClick={() => handleClick('non-threatening, unsure')}>non-threatening, unsure</Button> at first,
          but when the cat approached closer, his mood shifted to <Button onClick={() => handleClick('angry')}>angry</Button> and <Button onClick={() => handleClick('potentially aggressive')}>potentially aggressive.</Button>
          </Text>
      </Box>
      <Box flex="1" minWidth={{ base: "100%", lg: "30%" }} mb={{ base: 4, lg: 0 }}  position="relative">
        <Image src={catMoods} alt="Cat Moods" width="100%" mx="auto" />
          <div ref={tailRefs['friendly and content']} className="tail-overlay tail_c1" />
          <div ref={tailRefs['alert and interested']} className="tail-overlay tail_c12" />
          <div ref={tailRefs['derisive']} className="tail-overlay tail_c3" />
          <div ref={tailRefs['friendly but unsure']} className="tail-overlay tail_c4" />
          <div ref={tailRefs['amicable']} className="tail-overlay tail_c5" />
          <div ref={tailRefs['defensive and angry']} className="tail-overlay tail_c6" />
          <div ref={tailRefs['submissive']} className="tail-overlay tail_c9" />
          <div ref={tailRefs['very happy']} className="tail-overlay tail_c10" />
          <div ref={tailRefs['excited and irritable']} className="tail-overlay tail_c11" />
          <div ref={tailRefs['non-threatening, unsure']} className="tail-overlay tail_c2" />
          <div ref={tailRefs['angry']} className="tail-overlay tail_c7" />
          <div ref={tailRefs['potentially aggressive']} className="tail-overlay tail_c8" />
      </Box>
      <Box flex="1" minWidth={{ base: "100%", lg: "30%" }} pl={{ lg: 4 }}>
        <Text mb={4}>
          Whiskers calmed down as the other cat left, accepting pets in a <Button onClick={() => handleClick('submissive')}>submissive</Button> mood.
          As evening fell, he was <Button onClick={() => handleClick('very happy')}>very happy,</Button> snuggled in a blanket.
          A sudden noise made him <Button onClick={() => handleClick('excited and irritable')}>excited and irritable,</Button> but he quickly calmed down, ending his day <Button onClick={() => handleClick('alert and interested')}>alert and interested,</Button> watching the stars from his perch.
        
        And with that, Whiskers curled up to sleep, having experienced the full range of his complex moods.
        
        </Text>
        <Button onClick={handleReset}>Reset</Button> the story to see the tail moods again.
      </Box>
    </Flex>
  );
};

export default WhiskersStory;





