
export const  calcPaths = (totalDur) => {
  // unset 'animated' class to body which will reset the animation
  document.body.classList.add('animated')

  // get all SVG elements - lines and dots
  const paths = document.querySelectorAll('.autograph__path')

  // path length variable
  let len = 0

  // animation delay length variable
  let delay = 0
  console.log('path length', paths.length)
  
  // escape if no elements found
  if (!paths.length) {
    return false
  }

  // set duration in seconds of animation to default if not set
  const totalDuration = totalDur || 7

  // calculate the full path length
  paths.forEach((path) => {
    const totalLen = path.getTotalLength()
    len += totalLen
  })

  paths.forEach((path) => {
    const pathElem = path

    // get current path length
    const totalLen = path.getTotalLength()

    // calculate current animation duration
    const duration = totalLen / len * totalDuration

    // set animation duration and delay
    pathElem.style.animationDuration = `${duration < 0.1 ? 0.1 : duration}s`
    pathElem.style.animationDelay = `${delay}s`

    // set dash array and offset to path length - this is how you hide the line
    pathElem.setAttribute('stroke-dasharray', totalLen)
    pathElem.setAttribute('stroke-dashoffset', totalLen)

    // set delay for the next path - added .2 seconds to make it more realistic
    delay += duration
  })
  // set 'animated' class to body which will start the animation
  document.body.classList.add('animated')
  return true;
};

export const getRandomComplementaryColor = (baseColor) => {
  // Convert the base color to RGB
  let rgb = hexToRgb(baseColor);
  
  // Convert RGB to HSL
  let hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
  
  // Generate a complementary hue with more variation
  let hueShift = 150 + Math.random() * 60; // This will give a range of 150 to 210 degrees
  let complementaryHue = (hsl.h + hueShift) % 360;
  
  // Randomize saturation and lightness more dramatically
  const saturation = Math.min(Math.max(Math.random() * 0.7 + 0.3, 0), 1); // Range: 0.3 to 1.0
  const lightness = Math.min(Math.max(Math.random() * 0.6 + 0.2, 0), 1);  // Range: 0.2 to 0.8
  
  // Convert back to RGB
  let complementaryRgb = hslToRgb(complementaryHue, saturation, lightness);
  
  // Convert RGB to hex
  return rgbToHex(complementaryRgb.r, complementaryRgb.g, complementaryRgb.b);
}

// Helper functions remain the same
function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function rgbToHsl(r, g, b) {
  r /= 255; g /= 255; b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return { h: h * 360, s: s, l: l };
}

function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let hue2rgb = function(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    }

    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h / 360 + 1/3);
    g = hue2rgb(p, q, h / 360);
    b = hue2rgb(p, q, h / 360 - 1/3);
  }

  return { 
    r: Math.round(r * 255), 
    g: Math.round(g * 255), 
    b: Math.round(b * 255) 
  };
}

function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}