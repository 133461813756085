import React, { useState, useRef } from 'react';
import { useTrace } from 'traceit-react-hook';
import { 
  Box, Heading, VStack, Text, Button, useBreakpointValue, Flex, UnorderedList, ListItem
} from '@chakra-ui/react';
import ResizableTargetElement from './ResizableTargetElement';
import TraceOptions from './TraceOptions';
import CodeBlock from './CodeBlock';
import { useCopyToClipboard } from './copyUtil';
import { FiCopy } from 'react-icons/fi';


const DemoInstructions = () => {
  return (
    <Box mb={4}>
      <Text fontWeight="bold" mb={2}>How to use this demo:</Text>
      <UnorderedList spacing={2}>
        <ListItem>Explore and adjust the Trace Options below</ListItem>
        <ListItem>Click "TRACE WITH SELECTED OPTIONS" to see the result</ListItem>
        <ListItem>Experiment with different settings to see how they affect the tracing animation</ListItem>
      </UnorderedList>
    </Box>
  );
};


const TraceitDemo = () => {
  const [isTracing, setIsTracing] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [options, setOptions] = useState({
    strokeColor: '#744e89',
    strokeWidth: 3,
    fillColor: null,
    fillOpacity: 0,
    gapPoint: 'top_right',
    redrawSpeed: 2000,
    strokeDasharray: [4, 10],
    lineCap: 'round',
    canvasPadding: 10,
  });
  
  const [targetElement, setTargetElement] = useState({
    width: 300,
    height: 200,
    text: 'Element to trace'
  });
  const elementRef = useRef(null);
  const containerRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const copyToClipboard = useCopyToClipboard();

  const handleResizeStart = () => {
    setIsResizing(true);
    setIsTracing(false);
  };

  const handleResizeEnd = () => {
    setIsResizing(false);
  };

  const toggleTracing = () => {
    if (!isResizing) {
      setIsTracing(!isTracing);
    }
  };

  const updateTargetElement = (newProps) => {
    setTargetElement(prev => ({...prev, ...newProps}));
  };

  const handleOptionChange = (key, value) => {
    setOptions(prev => ({ ...prev, [key]: value }));
    setIsTracing(false);
  };

  const applyPreset = (preset) => {
    setOptions(preset.options);
    setIsTracing(false);
  };

  const handleCopyJSON = () => {
    const jsonString = JSON.stringify(options, null, 2);
    copyToClipboard(
      jsonString,
      "The preset configuration has been copied to your clipboard.",
      "There was an error copying the configuration. Please try again."
    );
  };

  return (
    <Box>
      <Heading as="h2" size="lg" mb={{ base: 2, md: 3 }}>Playground</Heading>
      <DemoInstructions />
      <VStack spacing={6} align="stretch">
        <Flex direction={{ base: "column", lg: "row" }} gap={{ base: 4, lg: 20 }} justify="space-between">
          <Box width={{ base: "100%", lg: "48%" }}>
             <TraceOptions options={options} handleOptionChange={handleOptionChange} applyPreset={applyPreset} />
          </Box>
          <Box width={{ base: "100%", lg: "48%" }} ref={containerRef}>
            <Text fontSize="xl" mb={4}>Target Element</Text>
            <ResizableTargetElement
              targetElement={targetElement}
              updateTargetElement={updateTargetElement}
              elementRef={elementRef}
              onResizeStart={handleResizeStart}
              onResizeEnd={handleResizeEnd}
            />
            <Button 
              mt={4} 
              onClick={toggleTracing} 
              width={isMobile ? "100%" : "auto"}
              isDisabled={isResizing}
            >
              {isTracing ? 'HIDE' : 'TRACE WITH SELECTED OPTIONS'}
            </Button>

            <Text fontSize="xl" mb={4} mt={4}>useTrace options prop</Text>
            <Box overflowY="auto">
              <CodeBlock code={JSON.stringify(options, null, 2)} language="javascript" />
            </Box>
            <Button 
                size="sm" 
                onClick={handleCopyJSON} 
                leftIcon={<FiCopy />}
              >
                Copy JSON
              </Button>
          </Box>
        </Flex>
      </VStack>
      
      {useTrace(elementRef, isTracing && !isResizing, options)}
    </Box>
  );
};

export default TraceitDemo;