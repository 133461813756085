import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Gallery from './Gallery'
import CV from './CV';

import '../App.css';

export default function Layouts() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/traceit" element={<About />} />
      <Route path="/cv" element={<CV />} />
      <Route path="/art" element={<Gallery />} />
    </Routes>
  );
}