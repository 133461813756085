import React from 'react';
import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Input,
  HStack,
  Tooltip,
  Select,
  FormLabel,
} from '@chakra-ui/react';

const CombinedStrokePicker = ({ strokeColor, strokeWidth, lineCap, onStrokeColorChange, onStrokeWidthChange, onLineCapChange }) => {
  return (
    <HStack>
    <Flex direction="column" width="100%">
      <Flex align="center" mb={2}>
        <Input
          type="color"
          value={strokeColor}
          onChange={(e) => onStrokeColorChange(e.target.value)}
          width="40px"
          height="40px"
          padding={0}
          border="none"
          borderRadius="md"
          cursor="pointer"
          mr={2}
        />
          <Slider
            aria-label="stroke-width"
            defaultValue={strokeWidth}
            min={1}
            max={15}
            onChange={onStrokeWidthChange}
            width="calc(100% - 150px)"
          >
            <Tooltip label="Stroke Width" hasArrow>
            <SliderTrack>
              <SliderFilledTrack backgroundColor={strokeColor} />
            </SliderTrack>
            </Tooltip>
            <SliderThumb boxSize={6}>
              <Flex
                justify="center"
                align="center"
                width="100%"
                height="100%"
                borderRadius="full"
                backgroundColor={strokeColor}
              >
                <span style={{ color: 'white', fontSize: '15px', fontWeight: 'bold' }}>
                  {strokeWidth}
                </span>
              </Flex>
            </SliderThumb>
          </Slider>
        <Select 
          value={lineCap} 
          onChange={(e) => onLineCapChange(e.target.value)}
          size="sm"
          width="90px"
          ml={2}
        >
          {['butt', 'round', 'square'].map((cap) => (
            <option key={cap} value={cap}>{cap}</option>
          ))}
        </Select>
      </Flex>
      <Flex align="center">
        <FormLabel fontSize="xs" width="40px" mr={2}>Color</FormLabel>
        <FormLabel fontSize="xs" width="calc(100% - 150px)">Width</FormLabel>
        <FormLabel fontSize="xs" width="90px" ml={2}>Line Cap</FormLabel>
      </Flex>
      <Flex 
        width="100%" 
        height={`${strokeWidth}px`} 
        backgroundColor={strokeColor} 
        borderRadius={lineCap === 'round' ? 'full' : 'none'}
        mb={6}
      />
    </Flex>
    </HStack>
  );
};

export default CombinedStrokePicker;