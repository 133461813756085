import { useToast } from '@chakra-ui/react';

export const useCopyToClipboard = () => {
  const toast = useToast();

  const copyToClipboard = (text, successMessage = "Copied to clipboard", errorMessage = "Failed to copy") => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Copy Successful",
        description: successMessage,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      toast({
        title: "Copy Failed",
        description: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return copyToClipboard;
};