import React, { useEffect, useState, useRef } from 'react';
import { Resizable } from 're-resizable';
import { Box, Text, Button, VStack, useBreakpointValue, Tooltip } from '@chakra-ui/react';
import { FiMaximize } from 'react-icons/fi';

const ResizableTargetElement = ({ 
  targetElement, 
  updateTargetElement, 
  elementRef,
  onResizeStart,
  onResizeEnd
}) => {
  const [size, setSize] = useState({ width: targetElement.width, height: targetElement.height });
  const containerRef = useRef(null);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const initialSize = { width: 300, height: 200 };
  const minSize = { width: 150, height: 100 };

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const newWidth = Math.max(Math.min(targetElement.width, containerWidth), initialSize.width);
        const newHeight = Math.max(Math.min(targetElement.height, containerHeight), initialSize.height);
        
        setSize({
          width: newWidth,
          height: newHeight
        });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [targetElement.width, targetElement.height]);

  const handleResize = (e, direction, ref, d) => {
    const newWidth = Math.max(size.width + d.width, minSize.width);
    const newHeight = Math.max(size.height + d.height, minSize.height);
    setSize({ width: newWidth, height: newHeight });
  };

  const handleResizeStop = (e, direction, ref, d) => {
    onResizeEnd();
    const newWidth = Math.max(size.width + d.width, minSize.width);
    const newHeight = Math.max(size.height + d.height, minSize.height);
    updateTargetElement({
      width: newWidth,
      height: newHeight,
    });
  };

  return (
    <Box ref={containerRef} width="100%" minHeight="200px">
      <VStack spacing={4} align="stretch">
        <Resizable
          size={size}
          onResizeStart={onResizeStart}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          minWidth={200}
          minHeight={150}
          maxWidth="100%"
          maxHeight={400}
          enable={{
            top: false,
            right: true,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: true,
            bottomLeft: false,
            topLeft: false
          }}
          handleComponent={{
            bottomRight: (
              <Tooltip label="Resize" placement="top-start">
                <Box
                  position="absolute"
                  right={0}
                  bottom={0}
                  width="20px"
                  height="20px"
                  borderRight="2px solid"
                  borderBottom="2px solid"
                  borderColor="blue.400"
                  cursor="se-resize"
                />
              </Tooltip>
            )
          }}
        >
          <Box
            ref={elementRef}
            width="100%"
            height="100%"
            bg="gray.100"
            display="flex"
            alignItems="center"
            justifyContent="center"
            border="2px dashed"
            borderColor="gray.300"
            borderRadius="md"
            boxShadow="md"
            transition="all 0.3s"
            _hover={{
              bg: "gray.200",
              boxShadow: "lg"
            }}
          >
            <Text color="gray.600" fontSize="lg" fontWeight="bold">
              {targetElement.text}
              <br />
              {Math.round(size.width)} x {Math.round(size.height)}
            </Text>
          </Box>
        </Resizable>
        
      </VStack>
    </Box>
  );
};

export default ResizableTargetElement;