import React from 'react';
import './ContactMe.css';
import LinkedInIcon from './LinkedInIcon';
import SvgSignature from '../SvgSignature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';


const ContactMe = ({ size, handleAnimationEnd }) => {
    return (
        <div className="get-in-touch-card">
            <div className="contact-icons">
                <LinkedInIcon href="https://www.linkedin.com/in/yunap" size={size} />
                <a href="mailto:yunay@yahoo.com" className="social-icon email" aria-label="Email">
                <FontAwesomeIcon icon={faEnvelope} />
                </a>
            </div>
            
            <hr className="divider" />
            
            <div className="contact-info">
                <p><b>Reach out: </b>yunay@yahoo.com</p>
                <p><b>Address:</b> San Francisco Bay Area, CA</p>
                <div className="signature">
                    <SvgSignature handleAnimationEnd={handleAnimationEnd} />
                </div>
            </div>
        </div>
    );
};
export default ContactMe;