import React from 'react';
import {
  Box,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { FiEyeOff } from "react-icons/fi";

const CombinedFillPicker = ({ fillColor, fillOpacity, strokeColor, onFillColorChange, onFillOpacityChange }) => {
  const backgroundImage = `linear-gradient(45deg, #ccc 25%, transparent 25%), 
    linear-gradient(-45deg, #ccc 25%, transparent 25%), 
    linear-gradient(45deg, transparent 75%, #ccc 75%), 
    linear-gradient(-45deg, transparent 75%, #ccc 75%)`;

  const handleColorChange = (e) => {
    const newColor = e.target.value;
    onFillColorChange(newColor);
    if (fillColor === null) {
      onFillOpacityChange(0.5);
    }
  };

  const handleOpacityChange = (newOpacity) => {
    onFillOpacityChange(newOpacity);
    if (fillColor === null && newOpacity > 0) {
      onFillColorChange(strokeColor || '#000000');
    }
  };

  return (
    <Flex align="center" width="100%">
      <Box 
        width="40px" 
        height="40px" 
        borderRadius="md" 
        backgroundImage={backgroundImage}
        backgroundSize="20px 20px"
        backgroundPosition="0 0, 0 10px, 10px -10px, -10px 0px"
        mr={2}
        position="relative"
      >
        <Box 
          width="100%" 
          height="100%" 
          backgroundColor={fillColor || 'transparent'}
          opacity={fillColor === null ? 0 : fillOpacity}
          borderRadius="md"
        />
      </Box>
      <Box position="relative" width="60px" height="40px">
        <input
          type="color"
          value={fillColor || strokeColor || '#000000'}
          onChange={handleColorChange}
          style={{
            width: '100%',
            height: '100%',
            padding: 0,
            border: 'none',
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
        <Flex
          width="100%"
          height="100%"
          align="center"
          justify="center"
          bg="gray.100"
          borderRadius="md"
          position="absolute"
          top={0}
          left={0}
          pointerEvents="none"
        >
          {fillColor === null ? (
            <FiEyeOff size={24} />
          ) : (
            <Box width="80%" height="80%" bg={fillColor} borderRadius="sm" />
          )}
        </Flex>
      </Box>
      
        <Slider
          aria-label="fill-opacity"
          value={fillOpacity}
          min={0}
          max={1}
          step={0.01}
          onChange={handleOpacityChange}
          ml={4}
          width="calc(100% - 110px)"
        >
          <Tooltip label="Fill Opacity" hasArrow>
          <SliderTrack>
            <SliderFilledTrack backgroundColor={fillColor || strokeColor || 'gray.300'} />
          </SliderTrack>
          </Tooltip>
          <SliderThumb boxSize={6}>
            <Text color={fillColor || strokeColor || 'gray.500'} fontSize="xs" fontWeight="bold">
              {Math.round((fillColor === null ? 0 : fillOpacity) * 100)}%
            </Text>
          </SliderThumb>
        </Slider>
      
    </Flex>
  );
};

export default CombinedFillPicker;