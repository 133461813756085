import React, { useRef, useState } from 'react';
import { useTrace } from 'traceit-react-hook';
import { Box, Text, Button } from '@chakra-ui/react';
import './Intro.css';

export default function Intro() {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const options = {
      strokeColor: '#744e89',
      strokeWidth: 6,
      gapPoint: 'right',
      redrawSpeed: 2000,
      onEndTrace: () => console.log('Trace animation completed!'),
      onClick: (e) => console.log('Clicked on the trace!', e),
    };

    useTrace(elementRef, isVisible, options);

    return (
        <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                <span ref={elementRef} style={{ padding: '0.5rem', display: 'inline-block' }}>traceit-react-hook</span>
            </Text>
            <Text mb={4}>
                traceit-react-hook is a lightweight npm package that provides a custom hook for React implementing dynamic tracing of an element.
            </Text>
            <Button onClick={() => setIsVisible(!isVisible)} mb={4}>
                {isVisible ? 'Hide the trace' : 'Trace the title'}
            </Button>
            <Text>
                Perhaps you're looking for a way to provide your user with a visual cue to follow along with a story or tutorial – tracing part of an image can do this. To see it in action, select one of the bolded adjectives from the story below.
                Clicking on an element sets the tracing state and begins the trace for the corresponding tail-overlay div.
            </Text>
        </Box>
    );
}