import React from 'react';
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Tooltip,
  Wrap,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Flex,
} from '@chakra-ui/react';
import { FiPackage, FiPenTool, FiFeather, FiSettings, FiMoreHorizontal } from 'react-icons/fi';
import { StrokeDasharrayInput, DashPatternExample } from './StrokeDasharrayInput';
import CombinedStrokePicker from './CombinedStrokePicker';
import CombinedFillPicker from './CombinedFillPicker';
import {PresetPreview} from './PresetPreview';
import AdditionalProperties from './AdditionalProperties';

const TraceOptions = ({ options, handleOptionChange, applyPreset }) => {
  const dashPatternExamples = [
    [3, 3],
    [10, 10],
    [20, 10],
    [15, 3, 3, 3],
  ];

  const presetOptions = [
    {
      name: "Neon Outline",
      options: {
        strokeColor: '#00ff00',
        strokeWidth: 3,
        fillColor: null,
        fillOpacity: 0,
        gapPoint: 'right',
        redrawSpeed: 1500,
        strokeDasharray: [],
        lineCap: 'round',
      }
    },
    {
      name: "Dashed Blue",
      options: {
        "strokeColor": "#17a2de",
        "strokeWidth": 4,
        "fillColor": null,
        "fillOpacity": 0,
        "gapPoint": "bottom_left",
        "redrawSpeed": 2000,
        "strokeDasharray": [
          10,
          10
        ],
        "lineCap": "butt",
        "canvasPadding": 15
      }
    },
    {
      name: "Purple Fill",
      options: {
        "strokeColor": "#443450",
        "strokeWidth": 2,
        "fillColor": "#d1b0dd",
        "fillOpacity": 0.5,
        "gapPoint": "top_right",
        "redrawSpeed": 3800,
        "strokeDasharray": [
          10,
          10
        ],
        "lineCap": "round",
        "canvasPadding": 9
      }
    }
  ];
  

  return (
    <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <h2>
                <Tooltip label="Choose a preset style to quickly configure tracing options" placement="top" hasArrow openDelay={400}>
                <AccordionButton>
                  <Flex align="center" flex="1">
                    <FiPackage style={{ marginRight: '8px' }} />
                    <Text>Preset Options</Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                </Tooltip>
              </h2>
              <AccordionPanel pb={4}>
              <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                  {presetOptions.map((preset, index) => (
                    <PresetPreview
                      key={index}
                      preset={preset}
                      onApply={() => applyPreset(preset)}
                    />
                  ))}
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>

      {/* Stroke Properties Section */}
  
      <AccordionItem>
        <h2>
        <Tooltip label="Configure the outline of the traced element" placement="top" hasArrow openDelay={400}>
          <AccordionButton>
              <Flex align="center" flex="1">
                <FiPenTool style={{ marginRight: '8px' }} />
                <Text>Stroke Properties</Text>
              </Flex>
            <AccordionIcon />
          </AccordionButton>
          </Tooltip>
        </h2>
        <AccordionPanel pb={4}>
        <FormControl>
          <CombinedStrokePicker
            strokeColor={options.strokeColor}
            strokeWidth={options.strokeWidth}
            lineCap={options.lineCap}
            onStrokeColorChange={(color) => handleOptionChange('strokeColor', color)}
            onStrokeWidthChange={(width) => handleOptionChange('strokeWidth', width)}
            onLineCapChange={(cap) => handleOptionChange('lineCap', cap)}
          />
        </FormControl>
          <FormControl>
            <Tooltip label="Adjust stroke dasharray pattern. Example: [10, 5, 2, 5]" hasArrow openDelay={400}>
              <FormLabel fontSize="sm">Stroke Dasharray</FormLabel>
            </Tooltip>
            <StrokeDasharrayInput 
              value={options.strokeDasharray}
              onChange={(values) => handleOptionChange('strokeDasharray', values)}
              strokeColor={options.strokeColor} 
              strokeWidth={options.strokeWidth} 
            />
          </FormControl>
        </AccordionPanel>
      </AccordionItem>

      {/* Fill Properties Section */}
      <AccordionItem>
        <h2>
          <Tooltip label="Set the fill color and opacity of the traced element" placement="top" hasArrow openDelay={400}>
          <AccordionButton>
              <Flex align="center" flex="1">
                <FiFeather style={{ marginRight: '8px' }} />
                <Text>Fill Properties</Text>
              </Flex>
            <AccordionIcon />
          </AccordionButton>
          </Tooltip>
        </h2>
        <AccordionPanel pb={4}>
          <FormControl>
          <CombinedFillPicker
            fillColor={options.fillColor}
            fillOpacity={options.fillOpacity}
            strokeColor={options.strokeColor}
            onFillColorChange={(color) => handleOptionChange('fillColor', color)}
            onFillOpacityChange={(opacity) => handleOptionChange('fillOpacity', opacity)}
          />
          </FormControl>
        </AccordionPanel>
      </AccordionItem>
  

      {/* Additional Properties Section */}
      
      <AccordionItem>
        <h2>
        <Tooltip label="Adjust additional tracing behavior and appearance" placement="top" hasArrow openDelay={400}>
          <AccordionButton>
                <Flex align="center" flex="1">
                    <FiSettings style={{ marginRight: '8px' }} />
                    <Text>Additional Properties</Text>
                </Flex>
            <AccordionIcon />
          </AccordionButton>
          </Tooltip>
        </h2>
        <AccordionPanel pb={4}>
          <AdditionalProperties options={options} handleOptionChange={handleOptionChange} />
        </AccordionPanel>
      </AccordionItem>

      {/* Dash Pattern Examples */}
      
      <AccordionItem>
        <h2>
        <Tooltip label="Explore predefined dash patterns for the stroke" placement="top" hasArrow openDelay={400}>
          <AccordionButton>
          <Flex align="center" flex="1">
                <FiMoreHorizontal style={{ marginRight: '8px' }} />
                  <Text>Dash Pattern Examples</Text>
                </Flex>
            <AccordionIcon />
          </AccordionButton>
          </Tooltip>
        </h2>
        <AccordionPanel pb={4}>
          <Wrap spacing={2}>
            {dashPatternExamples.map((pattern, index) => (
              <DashPatternExample
                key={index}
                pattern={pattern}
                onClick={() => handleOptionChange('strokeDasharray', pattern)}
                strokeColor={options.strokeColor}
              />
            ))}
          </Wrap>
        </AccordionPanel>
      </AccordionItem>
      
    </Accordion>
  );
};

export default TraceOptions;
