import React, { useEffect } from 'react';
import { Box, Flex, Link, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { calcPaths } from './Helpers';
import SvgSignature from './SvgSignature';

const Footer = ({ handleAnimationEnd }) => {
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const hoverColor = useColorModeValue('blue.500', 'blue.300');
  useEffect(() => {
    calcPaths(3);
  }, []);


  return (
    <Box as="footer" mt={8} py={4} borderTop="1px solid" borderColor="gray.200">
      <Flex
        maxWidth="1200px"
        margin="0 auto"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: 'column', md: 'row' }}
        px={4}
      >
        <Flex mb={{ base: 4, md: 0 }}>
          <Link
            href="https://www.linkedin.com/in/yunap"
            isExternal
            mr={4}
            aria-label="LinkedIn Profile"
          >
            <Icon 
              as={FaLinkedin} 
              w={6} 
              h={6} 
              color={iconColor} 
              _hover={{ color: hoverColor }}
              transition="color 0.2s ease-in-out"
            />
          </Link>
          <Link
            href="mailto:yunay@yahoo.com"
            isExternal
            aria-label="Email"
          >
            <Icon 
              as={MdEmail} 
              w={6} 
              h={6} 
              color={iconColor} 
              _hover={{ color: hoverColor }}
              transition="color 0.2s ease-in-out"
            />
          </Link>
        </Flex>
        <Box maxWidth="200px" height="40px">
          <SvgSignature handleAnimationEnd={handleAnimationEnd} />
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;