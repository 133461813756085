import React from 'react';
import { VStack, Heading, Container } from '@chakra-ui/react';
import TraceitExample from './TraceitExample';
import Footer from './Footer';

export default function About() {
  return (
    <Container 
      maxWidth={["100%", "100%", "90%", "90%", "1200px"]} 
      margin="auto" 
      padding={{ base: 3, md: 4, lg: 6 }}
    >
      <VStack spacing={{ base: 6, md: 8, lg: 10 }} align="stretch">
        <Heading as="h1" size="2xl">TraceIt React Hook</Heading>
        <TraceitExample />
      </VStack>
      <Footer />
    </Container>
  );
}