import React, { useState, useRef, useEffect } from 'react';
import ListOfPictures from '../components/pictures';
import ImageGallery from 'react-image-gallery';
import { IconButton, Box, Tooltip } from "@chakra-ui/react";
import { useMediaQuery } from '@chakra-ui/react'
import { FiGrid, FiMaximize2 } from "react-icons/fi";
import Footer from './Footer';
import "react-image-gallery/styles/css/image-gallery.css";
import './Gallery.css';

export default function Gallery() {
  const [viewMode, setViewMode] = useState('grid');
  const [startIndex, setStartIndex] = useState(0);
  const galleryRef = useRef(null);

  const [isSmallerThan480] = useMediaQuery('(max-width: 480px)')

  const galleryImages = ListOfPictures.map(picture => ({
    original: picture.url,
    thumbnail: picture.url,
    description: picture.title,
    originalClass: picture.className
  }));

  useEffect(() => {
    if (viewMode === 'carousel') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  
    return () => {
      document.body.style.overflow = '';
    };
  }, [viewMode]);

  function handleImageClick(index) {
    setStartIndex(index);
    setViewMode('carousel');
    document.body.style.overflow = 'hidden'; // Prevent scrolling
  }
  
  const handleViewModeToggle = () => {
    if(viewMode === 'grid') {
     //scroll to top
    }
    setViewMode(viewMode === 'grid' ? 'carousel' : 'grid');
  };

  const renderCustomControls = () => {
    return (
      <IconButton
        icon={<FiGrid />}
        onClick={handleViewModeToggle}
        aria-label="Switch to grid view"
        className="image-gallery-custom-action"
        colorScheme='BlackAlpha'
        size={isSmallerThan480 ? 'sm' : 'md'}
        sx={{margin: '2px 6px 6px 6px'}}
      />
    );
  };
  

  return (
    <Box 
    className={`gallery-container ${viewMode === 'carousel' ? 'fullscreen' : ''}`} 
    ref={galleryRef}
    >
      {viewMode === 'grid' ? (
        <>
          <div className='main-galery'>
            {ListOfPictures.map((picture, index) => (
              <div key={picture.id} className={picture.className}>
                <span className='tag'>{picture.title}</span>
                <img 
                  src={picture.url} 
                  alt={picture.title} 
                  onClick={() => handleImageClick(index)} 
                />
              </div>
            ))}
          
          <Tooltip label="Switch to Carousel" placement="left">
            <IconButton
              icon={<FiMaximize2 />}
              onClick={handleViewModeToggle}
              position="fixed"
              bottom="40px"
              right="40px"
              colorScheme="gray"
              size={isSmallerThan480 ? 'md' : 'lg'}
              isRound
              boxShadow="md"
              aria-label="Switch to Carousel"
            />
          </Tooltip>
          </div>
        </>
      ) : (
        <ImageGallery 
          items={galleryImages}
          showPlayButton={true}
          showFullscreenButton={true}
          showThumbnails={!isSmallerThan480}
          showBullets={false}
          showNav={true}
          isRTL={false}
          slideDuration={300}
          slideInterval={3000}
          startIndex={startIndex}
          onSlide={(currentIndex) => {
            setStartIndex(currentIndex);
          }}
          renderCustomControls={renderCustomControls}
          thumbnailPosition={!isSmallerThan480 ? "left" : "bottom"}
        />
      )}
      <Footer />
    </Box>
  );
}