import React from 'react';
import { Box, VStack, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import TraceitDemo from './TraceitDemo';
import Intro from './Intro';
import Usage from './Usage';
import WhiskersStory from './WhiskersStory';

const TraceitExample = () => {
  return (
    <Box width="100%">
      <Tabs>
        <TabList>
          <Tab>Intro</Tab>
          <Tab>Usage</Tab>
          <Tab>Playground</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack spacing={8} align="stretch">
              <Intro />
              <WhiskersStory />
            </VStack>
          </TabPanel>
          <TabPanel>
            <Usage />
          </TabPanel>
          <TabPanel>
            <TraceitDemo />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TraceitExample;