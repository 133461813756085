import { useRef } from 'react';
import ContactMe from './ContactMe';
import { calcPaths } from './Helpers';
import './Home.css';

import { useTrace } from 'traceit-react-hook';

export default function Home() {

  const elementRef = useRef(null);
  useTrace(elementRef, true, {
    strokeWidth: 4,
    strokeColor: "#50527f",
    gapPoint: "bottom_left",
    redrawSpeed: 2000,
    onEndTrace: () => handleAnimationEnd(),
  });

  const handleAnimationEnd = () => {
    calcPaths(3);
    console.log('Animation ended!');
  };

  const goToTraceIt = () => {
    window.location.href = '/traceit';
  };

  return (
    <div className="App-main sandbox">
      <div className="App-page">
      <section>
        <div className="greeting-all">
          <div>
          <h1 className="title-home"><span className="transparent-bg">Y's </span><span className="transparent-bg" ref={elementRef}>SandBox</span></h1>
          <h3 className="subtitle-home"><span className="transparent-bg">a space for me to play around</span></h3>
          </div>
          <div className="sand-footer-lg" onClick={goToTraceIt}></div>
        </div>
        <div className="greeting">
          <span>Hello, I am Yuna. I love design and puzzles. I am a full-stack programmer.
          Being a software developer of web-based systems continues to fascinate me as much as it did ten years ago.
          I'm looking for a dynamic workplace, smart peers, and an innovative product to contribute to.</span>
        </div>
        
        <h2 className="card-title"><span className="transparent-bg">Get in touch</span></h2>
        <div className="card-description">
        <span className="transparent-bg">Have a question about my projects, or want to suggest a new feature for traceit?</span>
        </div>
        <ContactMe size={24} handleAnimationEnd={handleAnimationEnd} /></section>
        <footer className="sand-footer" onClick={goToTraceIt} ></footer>
      </div>
    </div>
  );
}