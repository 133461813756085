/**
 * This file contains the routes for handling images in Yuna's Album 
 */

import image1 from '../images/Steampunk_1.2.JPG';
import image2 from '../images/Thea.JPG';
import image3 from '../images/Steampunk_1.6.JPG';
import image4 from '../images/Provance.JPG';
import image5 from '../images/Сирень.jpg';
import image6 from '../images/IMG_0964.JPG';
import image7 from '../images/Rotorua.jpg';
import image8 from '../images/натюрморт  1.jpg';
import image9 from '../images/Transition.JPG';
import image10 from '../images/натюрморт 2.jpg';
import image11 from '../images/Steampunk_1.7.JPG';
import image12 from '../images/blue_2020.png';
import image13 from '../images/без названия.jpg';
import image14 from '../images/Лиса Ах.ли.jpg';
import image15 from '../images/IMG_0974.JPG';
import image16 from '../images/IMG_0975.JPG';
import image17 from '../images/Tartu.JPG';
import image18 from '../images/Сон.jpg';
import image19 from '../images/Рыба.JPG';
import image20 from '../images/Рыба 2.JPG';
import image21 from '../images/Yellowstone.JPG';
import image22 from '../images/Steampunk_1.4.JPG';
import image23 from '../images/Two_2006.jpg';
import image24 from '../images/Berta_2023.jpg';
import image25 from '../images/детство.jpg';
import image26 from '../images/California1.jpg';
import image27 from '../images/California2.jpg';
import image28 from '../images/Georgia_2006.jpg';
import image29 from '../images/2020_November.jpg';
import image30 from '../images/woman_in_yellow_hat.jpg';
import image31 from '../images/2020_November_two.jpg';
import image32 from '../images/asparagus.jpg';
import image33 from '../images/COVER.jpg';
import image34 from '../images/PXL_20210307.PORTRAIT.jpg';
import image35 from '../images/PXL_20210609_233624198.jpg';



const ListOfPictures = [
  {
    title: "Steam punk",
    url: image1,
    id: "1",
  },
  {
    title: "Thea",
    url: image2,
    id: "2",
  },
  {
    title: "Steam punk",
    url: image3,
    className: "tall",
    id: "3",
  },
  {
    title: "Provence",
    url: image4,
    className: "wide",
    id: "4",
  },
  {
    title: "Сирень",
    url: image5,
    id: "5",
  },
  {
    title: "Headshot 1",
    url: image6,
    className: "tall",
    id: "6",
  },
  {
    title: "Rotorua",
    url: image7,
    className: "big",
    id: "7",
  },
  {
    title: "Still life 1",
    url: image8,
    id: "8",
  },
  {
    title: "Transition",
    url: image9,
    className: "wide",
    id: "9",
  },
  {
    title: "Still life 2",
    url: image10,
    id: "10",
  },
  {
    title: "Steam punk 1.7",
    url: image11,
    className: "tall",
    id: "11",
  },
  {
    title: "Blue 2020",
    url: image12,
    id: "12",
  }, 
  {
    title: "Without name",
    url: image13,
    id: "13",
  },
  {
    title: "Лиса Ах.ли",
    url: image14,
    className: "big",
    id: "14",
  },
  {
    title: "Headshot 4",
    url: image15,
    id: "15",
  },
  {
    title: "Headshot 5",
    url: image16,
    className: "wide",
    id: "16",
  },
  {
    title: "Tartu",
    url: image17,
    className: "wide",
    id: "17",
  },
  {
    title: "Сон",
    url: image18,
    className: "big",
    id: "18",
  },
  {
    title: "Fish",
    url: image19,
    className: "wide",
    id: "19",
  },
  {
    title: "Fish 2",
    url: image20,
    id: "20",
  },
  {
    title: "Yellowstone",
    url: image21,
    className: "wide",
    id: "21",
  },
  {
    title: "Steam punk 1.4",
    url: image22,
    id: "22",
  },
  {
    title: "Two",
    url: image23,
    id: "23",
  },
  {
    title: "Berta 2023",
    url: image24,
    className: "big",
    id: "24",
  },
  {
    title: "Детство",
    url: image25,
    className: "tall",
    id: "25",
  },
  {
    title: "California One",
    url: image26,
    className: "wide",
    id: "26",
  },
  {
    title: "Yellow Hat",
    url: image30,
    id: "27",
  },
  {
    title: "November 2020",
    url: image29,
    id: "28",
  },
  {
    title: "California Two",
    url: image27,
    className: "wide",
    id: "29",
  },
  {
    title: "Georgia 2006",
    url: image28,
    id: "30",
  },
  {
    title: "November 2020, Part 2",
    url: image31,
    className: "wide",
    id: "31",
  },
  {
    title: "Asparagus",
    url: image32,
    id: "32",
  },
  {
    title: "Cover",
    url: image33,
    id: "33",
  },
  {
    title: "Portrait",
    url: image34,
    id: "34",
  },
  {
    title: "Portrait 2",
    url: image35,
    id: "35",
  }

];

export default ListOfPictures;
