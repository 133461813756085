import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import CodeBlock from './CodeBlock';
import { useTrace } from 'traceit-react-hook';
import { FiCopy } from 'react-icons/fi';
import { useCopyToClipboard } from './copyUtil';

export const PresetPreview = ({ preset, onApply }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const elementRef = useRef(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const previewRef = useRef(null);
  const copyToClipboard = useCopyToClipboard();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && tabIndex === 0) {
          setIsPreviewVisible(true);
        } else {
          setIsPreviewVisible(false);
        }
      });
    }, options);

    if (previewRef.current) {
      observer.observe(previewRef.current);
    }

    return () => {
      if (previewRef.current) {
        observer.unobserve(previewRef.current);
      }
    };
  }, [tabIndex]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    if (index === 0) {
      // Check visibility immediately when switching to Preview tab
      const entry = previewRef.current.getBoundingClientRect();
      const isVisible = entry.top >= 0 && entry.bottom <= window.innerHeight;
      setIsPreviewVisible(isVisible);
    } else {
      setIsPreviewVisible(false);
    }
  };

  const handleCopyJSON = () => {
    const jsonString = JSON.stringify(preset.options, null, 2);
    copyToClipboard(
      jsonString,
      "The preset configuration has been copied to your clipboard.",
      "There was an error copying the configuration. Please try again."
    );
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={2}>
      <Tabs isFitted variant="enclosed" index={tabIndex} onChange={handleTabChange}>
        <TabList mb="0.9em">
          <Tab fontSize="xs">View</Tab>
          <Tab fontSize="xs">JSON</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0} ref={previewRef}>
            <VStack spacing={2} align="stretch">
              <Box height="100px" position="relative">
                <Box
                  position="absolute"
                  top="15%"
                  left="15%"
                  width="70%"
                  height="70%"
                  borderWidth={preset.options.strokeWidth}
                  borderColor={preset.options.strokeColor}
                  borderStyle={preset.options.strokeDasharray.length ? 'dashed' : 'solid'}
                  borderRadius={preset.options.lineCap === 'round' ? 'md' : 'none'}
                />
                <div ref={elementRef} style={{ width: '100%', height: '100%' }} onClick={onApply} />
              </Box>
              { useTrace(elementRef, isPreviewVisible, preset.options) }
              <Button size="sm" onClick={onApply}>Select</Button>
            </VStack>
          </TabPanel>
          <TabPanel p={0}>
            <Box height="200px" overflowY="auto">
              <CodeBlock code={JSON.stringify(preset.options, null, 2)} language="javascript" />
            </Box>
            <Button 
                size="sm" 
                onClick={handleCopyJSON} 
                leftIcon={<FiCopy />}
              >
                Copy JSON
              </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};