import React from 'react';
import {
  VStack,
  Heading,
  Text,
  OrderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import { TraceitCodeExample, TraceitAPI } from './TraceitCodeExample';

const Usage = () => {
  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Heading as="h2" size="lg" mb={3}>Usage</Heading>
        <Text fontSize="md">
          To trace an element on the screen using the <code>useTrace</code> hook:
        </Text>
        <OrderedList fontSize="md" pl={6} mt={2}>
          <ListItem>Import the useTrace hook from traceit-react-hook.</ListItem>
          <ListItem>Create a ref using useRef for the element you want to trace.</ListItem>
          <ListItem>Call the useTrace hook with the ref, trace boolean, and any optional configuration.</ListItem>
          <ListItem>Attach the ref to the element you want to trace.</ListItem>
        </OrderedList>
      </Box>

      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h3" size="md">Code Example</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel pb={4}>
            <TraceitCodeExample />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h3>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Heading as="h3" size="md">API Reference</Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h3>
          <AccordionPanel pb={4}>
            <TraceitAPI />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default Usage;