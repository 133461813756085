import './App.css';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Layouts from './components/Layouts';
import appData from './components/AppConfig.json';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileOrSpecificPage, setIsMobileOrSpecificPage] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkIfMobileOrSpecificPage = () => {
      const isMobile = window.innerWidth <= 600;
      const isSpecificPage = location.pathname === '/art';
      setIsMobileOrSpecificPage(isMobile || isSpecificPage);
      setIsMobile(isMobile);
    };

    // Initial check
    checkIfMobileOrSpecificPage();

    // Add event listener to update on resize
    window.addEventListener('resize', checkIfMobileOrSpecificPage);

    return () => window.removeEventListener('resize', checkIfMobileOrSpecificPage);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  let nav = appData.nav.map((el) => (
    <li key={el.name}>
      <NavLink
        to={el.path}
        exact
        className={({ isActive }) => (isActive ? "active" : "")}
        onClick={closeMenu}
      >
        {el.name.toUpperCase()}
      </NavLink>
    </li>
  ));

  return (
    <div className={`App-main ${isMobileOrSpecificPage ? 'show-mobile-menu' : ''}`}>
      <header className="site-header">
        <div className="header-content">
          {/* Regular Desktop Menu */}
          {!isMobile && !isMobileOrSpecificPage && (
            <nav className="main-nav desktop">
              <ul>{nav}</ul>
            </nav>
          )}

          {/* Mobile-Style Menu (for mobile or specific page) */}
          {(isMobile || isMobileOrSpecificPage) && (
            <>
              <button className="hamburger" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <nav className={`main-nav mobile ${isMenuOpen ? 'open' : ''}`}>
                <ul>{nav}</ul>
              </nav>
              <div
                className={`overlay-hamburger ${isMenuOpen ? 'open' : ''}`}
                onClick={closeMenu}
              ></div>
            </>
          )}
        </div>
      </header>
      <Layouts />
    </div>
  );
}

export default App;