import React, { useEffect, useRef } from 'react';
import { Container } from '@chakra-ui/react';
import Footer from './Footer';
import './HeaderAndNav.css';
import './CV.css';
import './Education.css';


const ResumeItem = ({ letter, color, company, date, title, bullets, skills }) => {
    return (
      <div className="resume-item job-card">
        <div className="letter-box">
          {letter}
        </div>
        <div className="content">
          <h2>{company}</h2>
          <p className="date">{date}</p>
          <p className="title">{title}</p>
          <ul>
            {bullets.map((bullet, index) => (
              <li key={index}>{bullet}</li>
            ))}
          </ul>
          <div className="skills">
            {skills.map((skill, index) => (
              <span key={index} className="skill">{skill}</span>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const Education = () => {
    return (
      <div className="education-section">
        <h2 className="section-title">Education</h2>
        <div className="education-item">
          <div className="school-logo umsl">UMSL</div>
          <div className="education-details">
            <h3>University of Missouri-Saint Louis</h3>
            <p>CS, 1993 - 1995</p>
          </div>
        </div>
        <div className="education-item">
          <div className="school-logo tsnuk"></div>
          <div className="education-details">
            <h3>Taras Shevchenko National University of Kyiv</h3>
            <p>Physics, 1985 - 1990</p>
          </div>
        </div>
        
        <h2 className="section-title">Licenses & Certifications</h2>
        <div className="certification-item">
          <div className="cert-logo coursera">coursera</div>
          <p>Human-Computer Interaction - Coursera</p>
        </div>
        <div className="certification-item">
          <div className="cert-logo coursera">coursera</div>
          <p>C++ For C Programmers - Coursera</p>
        </div>
        <div className="certification-item">
          <div className="cert-logo coursera">coursera</div>
          <p>Programming Mobile Applications for Android Handheld Systems - 
          Coursera Course Certificates</p>
        </div>
      </div>
    );
  };

  const PersonalStatement = () => {
    return (
      <div className="personal-statement">
        <p>
          I'm a full-stack programmer. My current interests revolve around UI/UX front-end. Being a software developer of web-based
          systems continues to fascinate me as much as it did ten years ago. I enjoy a dynamic workplace, smart peers and an
          innovative product to contribute to.
        </p>
      </div>
    );
  };

const CVcontent = () => {
    return (
      <div className="resume">
        <PersonalStatement />
        <ResumeItem
          letter="L"
          color="#FF5733"
          company="endingClub"
          date="May 2016 - 2023"
          title="Principal Engineer"
          bullets={[
            "Led collaboration across multiple 6-10-person development teams and cross-functional partners in launching minimally viable products (MVPs) under tight timelines.",
            "Researched and benchmarked modern web framework technology (Module Federation) to inform future re-platforming initiatives.",
            "Created animated video tutorials and documentation that became integral onboarding/training materials adopted across departments.",
          ]}
          skills={["Leading Development Teams", "Analytics Software", "Splunk", "React.js", "Node.js", "JavaScript"
        ]}
        />
        <ResumeItem
          letter="L"
          color="#FF5733"
          company="endingClub"
          date=""
          title="Lead Software Engineer"
          bullets={[
            "Spearheaded front-end development effort for an extensive 7% conversion improvement initiative for partner personal loan funnel through a series of targeted A/B tests over 12 months.",
            "Enhanced functionality and UX led to increased profits.",
            "Engineered and oversaw end-to-end UI/UX implementation for new Personal Lending products (Balance Transfer, CreditKarma EasyApply), directly contributing to promotion from Lead to Principal Engineer.",
            "Provided knowledge transfer sessions and frequent mentorship to junior and senior engineers focused on UX and scalable architecture best practices.",
            "Introduced presentations formatted as animated video tutorials to more effectively convey complex information on UI/UX. Received company Best Presenter recognition."
          ]}
          skills={["Analytics Software", "Splunk", "React.js", "Node.js", "JavaScript", "npm"
        ]}
        />

        <ResumeItem
          letter="L"
          color="#FF5733"
          company="endingClub"
          date=""
          title="Senior UI Engineer"
          bullets={[
            "Drove complete re-platforming of front-end architecture for core Partner Experience web application, transitioning from monolithic to modular microservices-based structure.",
            "Implemented key new capabilities such as token-based user authentication and integration with Zendesk chat to improve customer experience.",
            "Took ownership of core Partner Lending Funnel, providing guidance to cross-functional teams on optimization."
          ]}
          skills={["Analytics Software", "Splunk", "Node.js", "HTML5", "CSS", "JavaScript", "Express", "npm", "SASS"
        ]}
        />

        <ResumeItem
          letter="P"
          color="#FF5733"
          company="eaxy, Inc."
          date="June 2014 - 2016"
          title="Senior UI Engineer"
          bullets={[
            "Used AngularJs framework In a fast paced startup environment to successfully overhaul web based user-interface to a System Management of the Peaxy® Hyperfiler® - a data aggregation and access platform for unstructured data.",
            "Designed and implemented a variety of individual application components, including flexible routing based on ui-router and ui-router-extras components, setup wizard, client side session management, user authorization and much more.",
            "Kick-started usability studies of the MSP GUI application. Gathered the results of the studies and restructured the application to make the application workflow and navigation accessible and meaningful. Data Visualization trends: researched and prototyped Horizon charts to spice up Hyperfiler® dashboard pages.",
          ]}
          skills={["AngularJS", "Bootstrap", "Responsive Web Design", "D3.js", "nvd3.js", "HTML5", "CSS3", "JavaScriptGulp", "Bower", "npm", "SASS"]}
        />

        <ResumeItem
          letter="F"
          color="#FF5733"
          company="reelancer, Burlingame, Ca"
          date="Sept. 2009 to 2013"
          title=""
          bullets={[
            "E-commerce (Praxis Product Design): Designed and created proof-of-concept using PrestaBox and Magento platforms. Prototyped design tools for letting customers create/review their own products and put them back in the store for sale, using Inkscape vector graphic editor on the back-end. Configured and deployed trac, subversion and the prototypes on AWS/EBS.",
            "Data Visualization (Automatiks): Created several dashboards and data visualizations using jQuery along with jqPlot, D3.js, and raphael.js.",
            "Quantified Self App: Prototyped a product from Need Finding to Proof-of-Concept and UX testing. MoodFlow was a well-being assistant comprising a wearable device and a smartphone app. Helped prompt users to become aware of their emotions, deepen self-awareness and enhance Emotional Intelligence.",
          ]}
          skills={["Photoshop", "Bootstrap", "Responsive Web Design", "JavaScript", "D3.js", "Raphael.js, jqPlot", "jQuery", "trigger.io", "balsamiq"]}
        />

    <ResumeItem
          letter="D"
          color="#FF5733"
          company="ailystrength.org"
          date="Dec. 2007 - 2008"
          title="Senior Software Engineer"
          bullets={[
            "Developed modular architecture to separate business logic from content and presentation.",
            "Designed and implemented a variety of individual site components, including groups, session management, authentication, friend/community activity lists; assisted in generalizing content/media storage.",
            "Worked closely with a senior front-end developer on rapid roll-out of new features, learned to love JQuery.",
            "Participated in rapid overhaul of existing features to utilize new frameworks and standardize presentation."
      ]}
          skills={["Linux", "PHP", "Unix shells", "Apache", "MySQL", "HTML", "CSS 3", "JavaScript"]}
        />

    <ResumeItem
          letter="Y"
          color="#FF5733"
          company="ahoo! Inc."
          date="Dec. 1996 - 2006"
          title="Technical Yahoo!"
          bullets={[
            "Designed, built and deployed original, highly visible and highly trafficked Yahoo Finance Message Boards.",
            "Gained years of expertise and practical experience in building scalable web-based systems, from designing layered server/client architecture, performance monitoring, alerting and load balancing to a state where horizontal scalability became a matter of trivial replication of back-end components."
      ]}
          skills={["FreeBSD", "C++", "C", "Perl", "PHP", "Unix shells", "Apache", "HTML", "CSS"]}
        />

        <ResumeItem
          letter="E"
          color="#FF5733"
          company="MPaC International, Inc."
          date="Dec. 1995 to 1996"
          title="Software Engineer"
          bullets={[
            "Collaborated on development of various components of EmpView Corporate Internet Software package. At the dawn of the Internet era, it provided full Internet Server functionality to any OEM X86 platform, essentially enabling any standard 486 or Pentium platform to function as a complete turnkey Corporate Internet Server. EmpView offered small ISPs and corporate users, both local and international, basic Internet functionality including E-mail, Web, and FTP and web-based user interface to all common administrative functions.",
            "Prototype gesture tools for letting customers create/review their own content and put them back in the store for sale."
      ]}
          skills={["FreeBSD", "C", "Perl", "PHP", "Unix shells", "Apache", "HTML"]}
        />
  

        <Education /> 
      </div>
    );
  };

const CV = () => {
    const observerRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('fade-in');
            }
          });
        }, { threshold: 0.1 });
    
        // Store the observer in a ref to avoid recreating it on every render
        observerRef.current = observer;
    
        // Observe all elements with class 'job-card' on initial render
        document.querySelectorAll('.job-card').forEach((card) => {
          observerRef.current.observe(card);
        });
    
        // Clean up the observer on component unmount
        return () => observerRef.current.disconnect();
      }, []);

    return (
        <div className="App-page cv-bg">
           <Container maxWidth={["100%", "100%", "90%", "1200px"]} margin="auto" padding={{ base: 3, md: 4 }}>
            <CVcontent />
            <Footer />
           </Container>
        </div>  
    );
};

export default CV;