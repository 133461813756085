import React from 'react';
import CodeBlock from './CodeBlock';

export const TraceitCodeExample = () => {
    const exampleCode = `
    import React, { useRef, useState } from 'react';
    import { useTrace } from 'traceit-react-hook';
    
    function MyComponent() {
      const elementRef = useRef(null);
      const [trace, setTrace] = useState(true);
      
      useTrace(elementRef, trace, {
        strokeWidth: 3,
        strokeColor: '#ff0000',
        fillColor: '#00ff00',
        fillOpacity: 0.3,
        gapPoint: 'top_right',
        onEndTrace: () => console.log('Animation completed!'),
      });
    
      return (
        <>
          <div ref={elementRef} style={{ width: 200, height: 100, background: '#f0f0f0' }}>
            Element being traced
          </div>
    
          <button onClick={() => setTrace(!trace)}>Toggle Trace</button>
        </>
      );
    } `;

  return (
    <div>
      <CodeBlock code={exampleCode} language="javascript" />
    </div>
  );
};

export const TraceitAPI = () => {
    const API = `
useTrace(elementRef, trace, options);

  elementRef: ref object pointing to the element to be traced
  trace: Boolean to enable/disable tracing (default: true)
  options: (Optional) An object with the following properties:
    strokeWidth: Width of the trace line (default: 2)
    strokeColor: Color of the trace line (default: '#000000')
    strokeDasharray: Dash pattern repeating [10, 5, 2, 5] 
                     10px line, 5px gap, 2px line, 5px gap (default: [])
    lineCap: Style of line endings ('butt', 'round', or 'square' default: 'round')
    gapPoint: Where the gap in the trace should be 
              ('top', 'right', 'bottom', 'left', 
               'top_right', 'bottom_right', 
               'bottom_left', 'top_left', default: 'top')
    redrawSpeed: Speed of the redraw animation
                 in milliseconds (default: 2500)
    fillOpacity: Opacity of the fill color (default: 0)
    fillColor: Color of the fill (default: 'none')
    canvasPadding: Padding around the traced element (default: 0)
    onEndTrace: Callback function called when tracing ends
      `;

  return (
    <div>
      <CodeBlock code={API} language="javascript" />
    </div>
  );
};