import React from 'react';
import { Box } from "@chakra-ui/react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeBlock = ({ code, language }) => {
  return (
    <Box borderRadius="md" overflow="auto" maxWidth="100%">
      <SyntaxHighlighter 
        language={language} 
        style={tomorrow}
        customStyle={{
          margin: 0,
          padding: "16px",
          fontSize: "12px",
        }}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CodeBlock;