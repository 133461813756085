import React from 'react';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  VStack,
  Box, 
  Text,
  Tooltip,
  Link,
  useColorModeValue,
  WrapItem
} from '@chakra-ui/react';

import { FiPlus, FiMinus,FiX, FiTrash2, FiExternalLink } from "react-icons/fi";
import { Button } from '@chakra-ui/react';

export const StrokeDasharrayInput = ({ value, onChange, strokeColor, strokeWidth }) => {
    const hrefDocs = "https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setLineDash";

    const addSegment = () => {
      onChange(value ? [...value, 5] : [5]);
    };
  
    const removeSegment = (index) => {
      if (value && value.length > 1) {
        onChange(value.filter((_, i) => i !== index));
      } else {
        onChange(null);
      }
    };
  
    const removeLastSegment = () => {
      if (value && value.length > 1) {
        onChange(value.slice(0, -1));
      } else {
        onChange(null);
      }
    };
  
    const updateSegment = (index, newValue) => {
      if (value) {
        const newArray = [...value];
        newArray[index] = Math.max(0, parseInt(newValue) || 0);
        onChange(newArray);
      }
    };
  
    const clearDashArray = () => {
      onChange(null);
    };
  
    const generateGradient = () => {
      if (!value || value.length === 0) return `to right, ${strokeColor}`;
      let gradient = 'to right, ';
      let position = 0;
      value.forEach((val, index) => {
        const isEven = index % 2 === 0;
        gradient += `${isEven ? strokeColor : '#ffffff'} ${position}px, `;
        position += val;
        gradient += `${isEven ? strokeColor : '#ffffff'} ${position}px${index < value.length - 1 ? ',' : ''}`;
      });
      return gradient;
    };
  
    return (
      <VStack spacing={4} align="stretch">
        <HStack>
          <Tooltip label="Add stroke dasharray pattern segment." hasArrow>
          <Button size="sm" onClick={addSegment}>
            <FiPlus size={16} />
          </Button>
            </Tooltip>
            <Tooltip label="Remove last stroke dasharray pattern segment." hasArrow>
          <Button size="sm" onClick={removeLastSegment} isDisabled={!value || value.length === 0}>
            <FiMinus size={16} />
          </Button>
          </Tooltip>
          <Tooltip label="Clear stroke dasharray pattern." hasArrow>
          <Button size="sm" onClick={clearDashArray} isDisabled={!value}>
            <FiTrash2 size={16} />
          </Button>
          </Tooltip>
        </HStack>
        <HStack wrap="wrap" spacing={2}>
          {value && value.map((val, index) => (
            <HStack key={index}>
              <NumberInput
                value={val}
                onChange={(valueString) => updateSegment(index, valueString)}
                min={0}
                max={100}
                size="sm"
              >
                <NumberInputField width="60px" />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Button 
                size="sm"
                variant="ghost" 
                onClick={() => removeSegment(index)}
              >
                <FiX size={16} />
              </Button>
            </HStack>
          ))}
        </HStack>
        <Box
          height={`${strokeWidth}px`}
          width="100%"
          bg="gray.200"
          position="relative"
          overflow="hidden"
        >
          <Box
            height="100%"
            width="100%"
            bgImage={`linear-gradient(${generateGradient()})`}
            bgSize={value ? `${value.reduce((a, b) => a + b, 0)}px 100%` : '100% 100%'}
            bgRepeat="repeat-x"
          />
        </Box>
        <Text fontSize="sm" color="gray.600">
          Current value: {value ? `[${value.join(', ')}]` : 'No dash array (solid line)'}
        </Text>
        <Text fontSize="xs" mt={2}>
          Learn more about{' '}
          <Link
            href={hrefDocs}
            fontWeight="medium"
            style={{ color: useColorModeValue('#2B6CB0', '#63B3ED') }}
            _hover={{ textDecoration: 'underline', color: 'blue.500' }}
            isExternal
          >
            Stroke Dash Arrays{' '}
            <FiExternalLink size={12} style={{ display: 'inline', verticalAlign: 'middle' }} />
          </Link>
        </Text>
      </VStack>
    );
  };

  export const DashPatternExample = ({ pattern, onClick, strokeColor }) => {
    const generateGradient = () => {
      let gradient = 'to right, ';
      let position = 0;
      pattern.forEach((val, index) => {
        const isEven = index % 2 === 0;
        gradient += `${isEven ? strokeColor : '#ffffff'} ${position}px, `;
        position += val;
        gradient += `${isEven ? strokeColor : '#ffffff'} ${position}px${index < pattern.length - 1 ? ',' : ''}`;
      });
      return gradient;
    };
  
    return (
      <WrapItem>
        <Button
          onClick={() => onClick(pattern)}
          variant="outline"
          size="sm"
          p={2}
          height="auto"
          width="80px"
        >
          <VStack spacing={1} width="100%">
            <Box
              height="16px"
              width="100%"
              bg="gray.200"
              position="relative"
              overflow="hidden"
            >
              <Box
                height="100%"
                width="100%"
                bgImage={`linear-gradient(${generateGradient()})`}
                bgSize={`${pattern.reduce((a, b) => a + b, 0)}px 100%`}
                bgRepeat="repeat-x"
              />
            </Box>
            <Text fontSize="xs">[{pattern.join(', ')}]</Text>
          </VStack>
        </Button>
      </WrapItem>
    );
  };
  