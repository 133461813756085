import React from 'react';
import {
  VStack,
  HStack,
  Text,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Box,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiClock, FiMove, FiMaximize, FiInfo } from 'react-icons/fi';

const AdditionalProperties = ({ options, handleOptionChange }) => {
  const tooltipBg = useColorModeValue('gray.700', 'gray.300');
  const tooltipColor = useColorModeValue('white', 'black');

  return (
    <VStack spacing={6} align="stretch">
      <Box>
        <Text fontWeight="bold" mb={2}>Tracing Behavior</Text>
        <VStack spacing={4} align="stretch">
          <HStack>
            <Tooltip label="Choose where the trace animation starts" openDelay={400}>
              <Box>
                <FiMove />
              </Box>
            </Tooltip>
            <Text flex="1">Gap Point</Text>
            <Tooltip 
              label="The gap point determines where the tracing animation starts and ends. It creates a visual break in the traced outline." 
              placement="top"
              hasArrow
              openDelay={400}
              bg={tooltipBg}
              color={tooltipColor}
              fontSize="sm"
              p={2}
              borderRadius="md"
            >
              <IconButton
                icon={<FiInfo />}
                variant="ghost"
                size="sm"
                aria-label="Gap Point Information"
              />
            </Tooltip>
            <Select
              value={options.gapPoint}
              onChange={(e) => handleOptionChange('gapPoint', e.target.value)}
              width="150px"
            >
              {['top', 'right', 'bottom', 'left', 'top_right', 'bottom_right', 'bottom_left', 'top_left'].map((point) => (
                <option key={point} value={point}>{point.replace('_', ' ')}</option>
              ))}
            </Select>
          </HStack>
          <HStack>
            <Tooltip label="Adjust the speed of the tracing animation" openDelay={400}>
              <Box>
                <FiClock />
              </Box>
            </Tooltip>
            <Text flex="1">Redraw Speed (ms)</Text>
            <Slider
              value={options.redrawSpeed}
              min={500}
              max={5000}
              step={100}
              onChange={(value) => handleOptionChange('redrawSpeed', value)}
              width="150px"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <Tooltip 
                label={`${options.redrawSpeed}ms`} 
                placement="top" 
                hasArrow 
                openDelay={400}
                bg={tooltipBg}
                color={tooltipColor}
              >
                <SliderThumb />
              </Tooltip>
            </Slider>
          </HStack>
        </VStack>
      </Box>

      <Box>
        <Text fontWeight="bold" mb={2}>Appearance</Text>
        <HStack>
          <Tooltip label="Set padding around the traced element" openDelay={400} hasArrow p={2}>
            <Box>
              <FiMaximize />
            </Box>
          </Tooltip>
          <Text flex="1">Canvas Padding</Text>
          <Slider
            value={options.canvasPadding}
            min={0}
            max={50}
            onChange={(value) => handleOptionChange('canvasPadding', value)}
            width="150px"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip 
              label={`${options.canvasPadding}px`} 
              placement="top" 
              hasArrow 
              openDelay={400}
              bg={tooltipBg}
              color={tooltipColor}
            >
              <SliderThumb />
            </Tooltip>
          </Slider>
        </HStack>
      </Box>
    </VStack>
  );
};

export default AdditionalProperties;